<template>
  <div class="mm-account">
    <BaseTooltip
      :placement="EPopperPlacement?.BottomEnd"
      :interactive="true"
      z-index="26"
      :arrow="false"
      @close:popper="isOpenPopup = false"
      @open:popper="isOpenPopup = true"
    >
      <div>
        <slot />
      </div>
      <template #content>
        <div
          v-if="isOpenPopup"
          class="mm-account__popup"
        >
          <ul class="mm-account__popup-menu">
            <li
              id="user_dropdown_menu_element"
              class="mm-account__popup-link mm-account__popup-link--header"
              @click="backNotificationService.openNotificationsDrawer()"
            >
              Уведомления
              <div
                v-if="backNotificationService.shortedUnreadCount.value"
                class="mm-account-notification__counter"
              >
                {{ backNotificationService.shortedUnreadCount.value }}
              </div>
            </li>
            <li
              v-for="(link, index) in currentMenuItems"
              id="user_dropdown_menu_element"
              :key="index"
              :class="['mm-account__popup-link', {
                'mm-account__popup-link--disabled': link?.disabled,
                'mm-account__popup-link--border__top': link?.isBorderTop,
              }]"
              @click="onAccountMenuClick(link)"
            >
              {{ link.label }}
            </li>
            <li
              class="mm-account__popup-link"
              :class="{
                'mm-account__popup-link--border__top': currentMenuItems?.every((link) => !link.isBorderTop),
              }"
              @click="onLogoutClick"
            >
              Выйти из аккаунта
            </li>
          </ul>
        </div>
      </template>
    </BaseTooltip>
  </div>
</template>

<script lang="ts" setup>
import { EPopperPlacement } from 'shared/enums/popperPlacement.enum';
import BaseTooltip from 'shared/components/BaseTooltip.vue';
import { BackNotificationService } from 'services/notification.service';
import { AuthManagerService } from 'services/auth/authManager.service';
import { useUserStore } from 'store/user.store';
import { MenuHelper } from 'utils/menuHelper.util';
import { IGroupMenuLinkItem } from 'shared/models/groupMenuLinkItem.model';

const userStore = useUserStore();
const currentMenuItems = MenuHelper.getMenuLinks(userStore?.groups);

const backNotificationService = inject<BackNotificationService>(BackNotificationService.getServiceName());

const isOpenPopup = ref(false);

function onAccountMenuClick(menuItem: IGroupMenuLinkItem) {
  if (menuItem?.disabled) {
    return;
  }

  isOpenPopup.value = false;
  if (menuItem?.actionFunction) {
    menuItem.actionFunction?.();
    return;
  }

  navigateTo(menuItem.path, { open: menuItem.target });
}

async function onLogoutClick() {
  isOpenPopup.value = false;
  await AuthManagerService.logout();
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';
.mm-account {
  :deep(.popper) {
    background-color: transparent !important;
    padding: 0;
  }

  &__popup {
    border-radius: 8px;
    width: 247px;
    box-shadow: 0 2px 18px $shadow;
    margin-top: 12px;
    background: $filter-popup-bc;

    &-menu {
      margin: 0;
      padding: 8px 0;
      width: 100%;
      display: block;
    }

    &-link {
      list-style-type: none;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      padding: 8px 20px;
      cursor: pointer;
      color: $text-dark-green;

      &:hover {
        color: $link;
      }

      &--border__top {
        border-top: 1px solid $dark-gray;
        padding-top: 16px;
        margin-top: 8px;
      }

      &--header {
        border-bottom: 1px solid $dark-gray;
        padding-top: 12px;
        padding-bottom: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      &--disabled {
        cursor: default;
        color: $light-green;

        &:hover {
          color: $light-green;
        }
      }
    }
  }

  &-notification__counter {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 1px 6px;
    border-radius: 12px;
    background-color: $notification-indicator-bg;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
